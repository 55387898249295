import htmx from 'htmx.org';

window.htmx = htmx;
document.body.addEventListener('htmx:configRequest', (event: Event) => {
  const custEvent = event as CustomEvent;
  let input = document.querySelector(
    'input[name="__RequestVerificationToken"]',
  ) as HTMLInputElement | null;

  let token = input?.value;
  if (token) {
    custEvent.detail.headers['X-CSRF-TOKEN'] = token;
  }
});
